import React, { FC, HTMLAttributes } from 'react';
import styled from '@emotion/styled';
import { mq } from '$lib/helpers';

type Props = {
    maxWidth?: number;
    style?: React.CSSProperties;
} & HTMLAttributes<HTMLDivElement>;

export const Container: FC<React.PropsWithChildren<Props>> = (props: React.PropsWithChildren<Props>): JSX.Element => (
    <StyledContainerLayout {...props}>{props.children}</StyledContainerLayout>
);

const StyledContainerLayout = styled.div<Props>(({ theme, maxWidth, style }) => ({
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
    [mq(0, 'frame')]: {
        padding: `0 ${theme.sizes.siteGutter}`,
    },
    [mq('frame')]: {
        maxWidth: maxWidth || theme.sizes.contentMaxWidth,
    },
    ...style,
}));

export default Container;
