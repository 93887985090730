// Do not alter this file, instead it should be generated by using `yarn svg`
// This will override this file and update the exported enum for use in the SvgIcon component

export enum BOISvg {
    arrowLeft = 'arrowLeft',
    arrowRight = 'arrowRight',
    bookOpen = 'bookOpen',
    book = 'book',
    btnSocialClub = 'btnSocialClub',
    btnSocialFacebook = 'btnSocialFacebook',
    btnSocialInstagram = 'btnSocialInstagram',
    btnSocialNewsletter = 'btnSocialNewsletter',
    btnSocialPinterest = 'btnSocialPinterest',
    checkmark = 'checkmark',
    chevronDown = 'chevronDown',
    chevronLeft = 'chevronLeft',
    chevronRight = 'chevronRight',
    circle = 'circle',
    click = 'click',
    contract = 'contract',
    creditCard = 'creditCard',
    cross = 'cross',
    disc = 'disc',
    equalizer = 'equalizer',
    fail = 'fail',
    frameWithTagline = 'frameWithTagline',
    frame = 'frame',
    gift = 'gift',
    headphones = 'headphones',
    heartSolid = 'heartSolid',
    heart = 'heart',
    history = 'history',
    house = 'house',
    info = 'info',
    list = 'list',
    lock = 'lock',
    loupe = 'loupe',
    mail = 'mail',
    mobilePay = 'mobilePay',
    money = 'money',
    pin1 = 'pin1',
    playFill = 'playFill',
    play = 'play',
    questionMark = 'questionMark',
    shoppingBag = 'shoppingBag',
    smartPhone = 'smartPhone',
    sort = 'sort',
    starFull = 'starFull',
    starHalf = 'starHalf',
    star = 'star',
    storeFront = 'storeFront',
    store = 'store',
    tag = 'tag',
    trash = 'trash',
    truck2 = 'truck2',
    truck = 'truck',
    userAlt = 'userAlt',
    wishCloud = 'wishCloud',
    youtube = 'youtube',
}