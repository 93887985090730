import { FC, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import shallow from 'zustand/shallow';
import { getSession, useSession } from 'next-auth/react';
import { useRouter } from 'next/router';
import { getLocalStorage, setLocalStorage } from '$services/local-storage';
import { LocalStorageKeys } from '$constants';
import { INavigation } from '$models';
import { Modal } from '../modal';
import { useTranslation } from '$hooks';
import { Flex } from '$components/layouts';
import { Button } from '../button';
import { Link } from '../link';
import { mq } from '$lib/helpers';
import { useAuthentication } from '~/store/authentication';

export type Props = {
    navigation: INavigation;
};

export const NewUserHandler: FC<Props> = ({ navigation }) => {
    const { data: session } = useSession();
    const { translate } = useTranslation();
    const [showNewUserModal, setShowNewUserModal] = useState(false);
    const { getAppUser, loadingAppUser } = useAuthentication(
        (state) => ({
            getAppUser: state.getAppUser,
            loadingAppUser: state.loadingAppUser,
        }),
        shallow
    );
    const { asPath } = useRouter();

    useEffect(() => {
        if (!session?.newUser || asPath.includes('auth/signout')) {
            return;
        }

        const validateUser = async () => {
            const sessionValidation = await getSession();
            if (!sessionValidation || !sessionValidation.accessToken) {
                return;
            }

            const userIsNew = (session.newUser as boolean) || false;
            // If users are being redirected to the myPageHome, we don't want to show the modal.
            if (userIsNew && asPath === navigation?.myPageHome?.path) {
                markNewUserPromptAsSeen();
                return;
            }
            if (!loadingAppUser) {
                // Start fetching appuser, since it might take some time
                getAppUser(session.accessToken as string);
            }
            shouldShowNewUserPrompt(userIsNew);
        };
        validateUser();
    }, [session]);

    const shouldShowNewUserPrompt = async (show?: boolean) => {
        const hasShown = await getLocalStorage(LocalStorageKeys.NewUserPrompt);
        if (!hasShown && show) {
            await setLocalStorage(LocalStorageKeys.NewUserPrompt, true);
            setShowNewUserModal(true);
        }
    };

    const markNewUserPromptAsSeen = async () => {
        await setLocalStorage(LocalStorageKeys.NewUserPrompt, true);
    };

    return (
        <NewUserModal
            title={session?.isBusinessUser ? translate('newBusinessUser.title') : translate('newUser.title')}
            show={showNewUserModal}
            onDismiss={() => setShowNewUserModal(false)}
        >
            <ModalContent>
                {session?.isBusinessUser ? translate('newBusinessUser.body') : translate('newUser.body')}
            </ModalContent>
            <NewUseButtonWrapper>
                <NewUserButton
                    as={Link}
                    onClick={() => setShowNewUserModal(false)}
                    variant="primary"
                    href={navigation?.myPageHome?.path}
                    aria-label={navigation?.myPageHome?.title}
                    applyTextStyling={false}
                >
                    {session?.isBusinessUser
                        ? translate('newBusinessUser.goToProfile')
                        : translate('newUser.goToProfile')}
                </NewUserButton>
                <NewUserButton variant="inverted" onClick={() => setShowNewUserModal(false)}>
                    {session?.isBusinessUser ? translate('newBusinessUser.continue') : translate('newUser.continue')}
                </NewUserButton>
            </NewUseButtonWrapper>
        </NewUserModal>
    );
};

const NewUserModal = styled(Modal)(({ theme }) => ({
    minWidth: 350,
    minHeight: 100,
    marginBottom: theme.space[5],
}));
const ModalContent = styled.div(({ theme }) => ({
    marginBottom: theme.space[7],
    maxWidth: 500,
}));
const NewUseButtonWrapper = styled(Flex)(({ theme }) => ({
    [mq('ml')]: {
        flexDirection: 'row',
        justifyContent: 'flex-end',
    },
    [mq(0, 'ml')]: {
        flexDirection: 'column',
    },
    gap: theme.space[3],
}));
const NewUserButton = styled(Button)(() => ({
    [mq(0, 'ml')]: {
        minWidth: '200px',
    },
}));
