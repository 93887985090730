import create from 'zustand';
// import { http } from '$lib/http';
// import { API_URL } from '$lib/helpers';
import { useSite } from '~/store';
import { ISameDayDeliveryShippingResponse } from '$models';

// const sameDayDeliveryUrl = `${API_URL}/scom/api/samedaydelivery/shipping`;

type SameDayDeliveryState = {
    sameDayShippingInfo?: ISameDayDeliveryShippingResponse;
    loadingSameDayDelivery: boolean;
    getSameDayDelivery: () => void;
    onSameDayDeliveryError: (errorTitle?: string, errorMessage?: string, unavoidableError?: boolean) => void;
    setSameDayDelivery: (sameDayDelivery?: ISameDayDeliveryShippingResponse) => void;
};

export const useSameDayDelivery = create<SameDayDeliveryState>((set, _) => ({
    sameDayShippingInfo: undefined,
    loadingSameDayDelivery: false,
    getSameDayDelivery: async () => {
        // TODO: Come back to BE is ready
        // set((state) => ({
        //     ...state,
        //     loadingSameDayDelivery: true,
        // }));
        // const { onSameDayDeliveryError, setSameDayDelivery } = get();
        // const sameDayDeliveryFetchResponse = await http(sameDayDeliveryUrl);
        // if (!sameDayDeliveryFetchResponse.ok) {
        //     onSameDayDeliveryError();
        //     return;
        // }
        // const fetchedInfo = await sameDayDeliveryFetchResponse.json();
        // setSameDayDelivery(fetchedInfo);
    },
    onSameDayDeliveryError: async (errorTitle?: string, errorMessage?: string, unavoidableError?: boolean) => {
        useSite
            ?.getState()
            ?.pushGlobalNotification(
                errorTitle ? errorTitle : 'giftShop.delivery.sameDayDeliveryErrorTitle',
                errorMessage ? errorMessage : 'giftShop.delivery.sameDayDeliveryErrorMessage',
                unavoidableError ? 'unavoidable' : 'error'
            );

        set((state) => ({
            ...state,
            loadingSameDayDelivery: false,
        }));
    },
    setSameDayDelivery: async (deliveryInfo?: ISameDayDeliveryShippingResponse) => {
        if (deliveryInfo) {
            set((state) => ({
                ...state,
                loadingSameDayDelivery: false,
                sameDayShippingInfo: {
                    isEnabled: !!deliveryInfo.isEnabled,
                    price: deliveryInfo.price ?? 0,
                    deliveryDeadline: deliveryInfo.deliveryDeadline ?? '',
                },
            }));
        }
    },
}));
