export async function getLocalStorage<T>(key: string): Promise<T | undefined> {
    try {
      const storageData = await localStorage.getItem(key);
      if (storageData) {
        return JSON.parse(storageData);
      }
    } catch (error) {
      return undefined;
    }
    return undefined;
  }
  
  export async function setLocalStorage<T>(key: string, data: T): Promise<void> {
    try {
      await localStorage.setItem(key, JSON.stringify(data));
    } catch (error) {
      return undefined;
    }
  }
  
  export const clearLocalStorage = async (key?: string) => {
    try {
      if (key) {
        await localStorage.removeItem(key);
      } else {
        await localStorage.clear();
      }
    } catch (error) {
      return undefined;
    }
  };
  