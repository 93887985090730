export enum LocalStorageKeys {
    AppState = 'appState',
    AppPadding = 'AppPadding',
    AppFavorites = 'AppFavorites',
    AppAnonymous = 'AppAnonymous',
    BasketId = 'BoiBasketId',
    CheckoutStateInfo = 'CheckoutStateInfo',
    IsMobilePayPayment = 'IsMobilePayPayment',
    NewUserPrompt = 'NewUserPrompt',
    ExistingUserPrompt = 'ExistingUserPrompt',
    SearchHistory = 'SearchHistory',
    HelloRetailTrackingUser = 'hello_retail_id',
    SearchSuggestionPosition = 'SearchSuggestionPosition',
}
