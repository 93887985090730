import create from 'zustand';
import { IMemberViewModel } from '$models';

type AppState = {
    isApp?: boolean;
    userInfo?: IMemberViewModel;
    appTopPadding?: number;
    favoriteIds?: string[];
    isAnonymous?: boolean;
    setIsApp: (isApp: boolean) => void;
    setUserInfo: (userInfo: IMemberViewModel) => void;
    setAppTopPadding: (padding: number) => void;
    setFavoriteIds: (ids: string[]) => void;
    addFavoriteId: (id: string) => void;
    removeFavoriteId: (id: string) => void;
    setIsAnonymous: (isAnonymous: boolean) => void;
};

export const useAppStore = create<AppState>((set, get) => ({
    isApp: false,
    userInfo: undefined,
    appTopPadding: 0,
    favoriteIds: [],
    isAnonymous: false,
    setIsApp: async (isApp) => {
        set((state) => ({
            ...state,
            isApp,
        }));
    },
    setUserInfo: (userInfo: IMemberViewModel) => {
        set((state) => ({
            ...state,
            userInfo,
        }));
    },
    setAppTopPadding: (padding: number) => {
        set((state) => ({
            ...state,
            appTopPadding: padding,
        }));
    },
    setFavoriteIds: (ids: string[]) => {
        set((state) => ({
            ...state,
            favoriteIds: ids,
        }));
    },
    addFavoriteId: (id: string) => {
        const { favoriteIds } = get();

        set((state) => ({
            ...state,
            favoriteIds: favoriteIds?.concat([id]),
        }));
    },
    removeFavoriteId: (id: string) => {
        const { favoriteIds } = get();

        set((state) => ({
            ...state,
            favoriteIds: favoriteIds?.filter((favoriteId) => favoriteId !== id),
        }));
    },
    setIsAnonymous: (isAnonymous: boolean) => {
        set((state) => ({
            ...state,
            isAnonymous,
        }));
    },
}));
