import create from 'zustand';
import { nanoid } from 'nanoid';

type NotificationSeverity = 'error' | 'warning' | 'info' | 'unavoidable';

export type AppNotification = {
    id: string;
    title: string;
    text?: string;
    severity: NotificationSeverity;
};

type SiteStates = {
    /**
     * Dictates whether the backdrop should be visible
     */
    backdrop: boolean;
    /**
     * Dictates whether the header should be always visible
     * or if it's currently hidedable
     */
    headerHideable: boolean;
    /**
     * Boolean to toggle when trustpilot been initialized
     */
    isTrustpilotInitialized?: boolean;
    /**
     * Boolean trustpilot error
     */
    isTrustpilotBlocked?: boolean;

    /**
     * Is true whenever the site is in transition state
     */
    inPageTransition?: boolean;
    raptorId?: string;
};

type SiteState = {
    notifications: AppNotification[];
    /**
     * Exposes current spa states
     * Use @function setSite to modify spa states
     */
    setSite: (newState: Partial<SiteStates>) => void;
    /**
     * Push a global notification shown in bottom right corner as a dismissable toast.
     * Will default to error status, as is tradition.
     */
    pushGlobalNotification: (title: string, text?: string, severity?: NotificationSeverity) => void;
    /**
     * Forcefully dismiss a notification by its id - for the busy customers.
     */
    dismissNotification: (id: string) => void;

    setTrustpilotInitialized: (value: boolean) => void;

    setTrustpilotBlocked: (value: boolean) => void;

    setRaptorId: (value: string) => void;
} & SiteStates;

export const useSite = create<SiteState>((set, get) => ({
    notifications: [],
    headerHideable: true,
    backdrop: false,
    trustPilotInitialized: false,
    inPageTransition: false,
    setSite: (newState: Partial<SiteStates>) => {
        set((state) => ({
            ...state,
            ...newState,
        }));
    },
    pushGlobalNotification: (title: string, text?: string, severity: NotificationSeverity = 'error') => {
        const notification = {
            title,
            text,
            severity,
            id: nanoid(),
        };

        set((state) => ({
            ...state,
            notifications: [...state.notifications, notification],
        }));

        if (severity !== 'unavoidable') {
            const { dismissNotification } = get();

            setTimeout(() => dismissNotification(notification.id), 5000);
        }
    },
    dismissNotification: (id: string) => {
        set((state) => ({
            ...state,
            notifications: state.notifications.filter((n) => n.id !== id),
        }));
    },
    setTrustpilotInitialized: (value: boolean) => {
        set((state) => ({
            ...state,
            isTrustpilotInitialized: value,
        }));
    },
    setTrustpilotBlocked: (value: boolean) => {
        set((state) => ({
            ...state,
            isTrustpilotBlocked: value,
        }));
    },
    setRaptorId: (value: string) => {
        set((state) => ({
            ...state,
            raptorId: value,
        }));
    },
}));
